<template>
    <div class="card">
        <div class="card-header card-header-bg flex-wrap gap-2">
            <div class="flex flex-wrap gap-2 lg:gap-5">
                <div class="flex"><label class="input input-sm"><i class="ki-filled ki-magnifier"></i><input value=""
                            v-model="searchTerm" type="text" placeholder="Search table" @input="fetchData"></label>
                </div>
            </div>
        </div>
      
        <div  @click="handleClick">
            <vue-good-table mode="remote" :server-side="true" :columns="columns" :rows="rows" :selectable="true"
               :total-rows="totalRows" :pagination-options="paginationOptions"
                :isLoading.sync="isLoading" :search-options="{ enabled: false }" @page-change="onPageChange"
                @on-page-change="onPageChange" @per-page-change="onPerPageChange" @sort-change="onSortChange">

                <template #table-row="props">
                    {{ props.formattedRow[props.column.field] }}
                </template>
            </vue-good-table>
        </div>
    </div>


</template>
<script>
import MainLayout from '@/components/MainLayout.vue'
import apiClient from '@/services/apigen'; // Import your configured Axios instance  
import { ref, watch, onMounted } from 'vue';
import { VueGoodTable } from 'vue-good-table-next';
import 'vue-good-table-next/dist/vue-good-table-next.css';



export default {
    components: {
        VueGoodTable,
    },
    setup(props, { emit }) { // Destructure emit from the context

        const columns = ref([
            { label: '#', field: 'product_id', type: 'number', sortable: true },
            { label: 'Product Code', field: 'product_code', sortable: true },
            { label: 'Product Name', field: 'product_name', sortable: true },
            { label: 'Selling Price', field: 'selling_price', sortable: true },
            { label: 'Original Price', field: 'original_price', sortable: true },
            { label: 'Running Balance', field: 'running_balance', sortable: true },
            { label: 'Discount Price', field: 'running_balance', sortable: true },
            { label: 'Total Value', field: 'total_value', sortable: true },
        ]);

        const rows = ref([]);
        const totalRows = ref(0);
        const searchTerm = ref('');
        const currentPage = ref(1);
        const perPage = ref(20);
        const sortField = ref('product_name');
        const sortType = ref('asc');
        const rowData = ref(null); // Define rowData using ref
      

        const paginationOptions = ref({
            enabled: true,
            mode: 'pages',
            // perPageDropdown: [10],
            perPage: perPage.value,
            totalRecords: totalRows.value,
            currentPage: currentPage.value

        });

        const searchOptions = {
            enabled: true
        };

        // Function to fetch data from the server
        const fetchData = async (page) => {

            const storedData = localStorage.getItem("selectedCategory");
            if (storedData) {
                rowData.value = JSON.parse(storedData);
                console.log("Loaded from Local Storage:", rowData.value.category_id);
            }

            try {
                const params = {
                    page: page,
                    per_page: perPage.value,
                    sort_field: sortField.value,
                    sort_type: sortType.value,
                    search: searchTerm.value,
                    category_id:rowData.value.category_id,
                    sub_category_id:rowData.value.sub_category_id
                };
                //console.log(rowData.value.category_id);

          


                const { data } = await apiClient.get('/product/list/getproduct_by_category', { params });
                rows.value = data.data || [];
                totalRows.value = data.total || 0;

            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        const onPageChange = ({ currentPage }) => {
            paginationOptions.currentPage = currentPage;
            fetchData(currentPage);
        };

        const onPerPageChange = ({ currentPerPage }) => {
            console.log('Per page changed to:', currentPerPage);
            perPage.value = currentPerPage;
            paginationOptions.value.perPage = currentPerPage;
            fetchData();
        };

        const onSortChange = ([sort]) => {
            console.log('Sort changed to:', sort);
            sortField.value = sort.field;
            sortType.value = sort.type;
            fetchData();
        };
        // Watch for changes to trigger fetchData
        watch([currentPage, perPage, sortField, sortType, searchTerm], fetchData, { immediate: true });
        return {
            columns,
            rows,
            totalRows,
            paginationOptions,
            searchTerm,
            onPageChange,
            onPerPageChange,
            onSortChange,
            isLoading: false,          
        };
    },
    methods: {

        handleClick(event) {
            const row = event.target.closest('tr');
            if (row) {
                const rowIndex = row.rowIndex; // Adjust based on your data structure
                //const rowData = this.rows[rowIndex-1];
                //alert(`Row clicked: ${JSON.stringify(rowData)}`);
                this.$emit('row-clicked',this.rows[rowIndex-1]);
            }
        },
    },
    data() {
        return {
            showModal: false,
            form: {},
            showModal2: false,
            currentItem: [

            ],
            countty_list: [],
            province_list: [],
            city_list: [],
            edit_first: 0,
            rowData:null
        };
    }
}
</script>
<style>
.vgt-table tbody tr:hover {
    background-color: #d1e7dd;
    /* Change to your desired color */
}
</style>