<template>
    <AccessControl :menuId="6" :menuSectionId="14" nameOfPage='Purchase Order list' @accessGranted="handleAccess">
    </AccessControl>
    <MainLayout>
        <template v-slot:default>
            <!-- end: container -->
            <!-- begin: container -->
            <div class="container-fixed">

                <div class="flex flex-wrap items-center lg:items-end justify-between gap-5 pb-7.5">
                    <div class="flex flex-col justify-center gap-2">
                        <h1 class="text-xl font-semibold leading-none text-gray-900">
                            Purchase Order List
                        </h1>
                    </div>
                    <div class="flex items-center gap-2.5">
                        <a v-if="canWrite" class="btn btn-sm btn-success" @click="openAdd">
                            Add Purchase Order
                        </a>
                    </div>
                </div>
                <Notification ref="notificationRef" />
                <div class="card">
                    <div class="card-header card-header-bg flex-wrap gap-2">
                        <div class="flex flex-wrap gap-2 lg:gap-5">
                            <div class="flex"><label class="input input-sm"><i class="ki-filled ki-magnifier"></i><input
                                        value="" v-model="searchTerm" type="text" placeholder="Search table"
                                        @input="fetchData"></label>

                                <label class="input input-sm" style="margin-left:10px;">Date From:<input value=""
                                        v-model="dateFrom" type="date" @input="fetchData"></label>


                                <label class="input input-sm" style="margin-left:10px;">Date From:<input value=""
                                        v-model="dateTo" type="date" @input="fetchData"></label>
                                <label class="input input-sm" style="margin-left:10px;">Status:
                                    <select class="bg-gray-50 border " placeholder="Posted"
                                            v-model="status" ref="purchase_status">
                                            <option value="0" selected>- Select -</option>
                                            <option value="1" selected>Posted</option>
                                            <option value="2" selected>Not Posted</option>
                                            <!-- Placeholder option -->
                                            <option v-for="category in category_list" :key="category.category_id"
                                                :value="category.category_id">
                                                {{ category.category_name }}
                                            </option>
                                    </select>    
                                </label>

                                <div>

                                    <button :disabled="export_loading" type="button" style="margin-left:10px;"
                                        @click="generate_report" ref="addRow"
                                        class="w-32 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 mr-1">
                                        <span v-if="export_loading" class="spinner"></span>
                                        <span v-else>Generate Report</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <vue-good-table mode="remote" :server-side="true" :columns="columns" :rows="rows"
                        :total-rows="totalRows" :pagination-options="paginationOptions" :isLoading.sync="isLoading"
                        :search-options="{ enabled: false }" @page-change="onPageChange" @on-page-change="onPageChange"
                        @per-page-change="onPerPageChange" @sort-change="onSortChange">
                        <template #table-row="props">
                            <span v-if="props.column.field === 'is_posted'">
                                {{ props.row.is_posted === 1 ? 'Yes' : 'No' }}
                            </span>                            
                            <span v-else>
                                {{ props.formattedRow[props.column.field] }}
                            </span>
                        </template>

                    </vue-good-table>

                </div>
            </div>
            <!-- end: container -->

            <Modal :isVisible="showModalDetail" @update:isVisible="showModalDetail = $event">
                <template v-slot:header>
                </template>
                <template v-slot:body>

                    <div style="padding:10px">
                        <h1 class="text-2xl font-bold mb-6" style="font-size:12px">Details</h1>
                        <div class="grid grid-cols-4 gap-4">
                            <!-- Customer Details Column -->
                            <div class="bg-blue-100 p-2 rounded-lg shadow-md">
                                <div class="col-span-2">
                                    <div class="justify-start mb-2">
                                        <p style="font-size:12px" class="font-semibold">Purchase Order Number:</p>
                                        <p style="font-size:12px">{{ base_info.purchase_order_number }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="bg-blue-100 p-2 rounded-lg shadow-md">
                                <div class="col-span-2">
                                    <div class="justify-start mb-2">
                                        <p class="font-semibold" style="font-size:12px">Purchase Date:</p>
                                        <p style="font-size:12px">{{ base_info.purchase_order_date }}</p>
                                    </div>
                                </div>
                            </div>

                            <div class="bg-blue-100 p-2 rounded-lg shadow-md">
                                <div class="col-span-2">
                                    <div class="justify-start mb-2">
                                        <p style="font-size:12px" class="font-semibold">Payment:</p>
                                        <p style="font-size:12px">{{ base_info.payment_name }}</p>
                                    </div>
                                </div>
                            </div>

                            <div class="bg-blue-100 p-2 rounded-lg shadow-md">
                                <div class="col-span-2">
                                    <div class="justify-start mb-2">
                                        <p style="font-size:12px" class="font-semibold">Payment Reference:</p>
                                        <p style="font-size:12px">{{ base_info.payment_reference }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="grid grid-cols-3 gap-4 pt-10 pb-10">
                            <div class="bg-blue-100 p-2 rounded-lg shadow-md">
                                <div class="col-span-1">
                                    <div class="justify-start mb-2">
                                        <p style="font-size:12px" class="font-semibold">Supplier:</p>
                                        <p style="font-size:12px">{{ base_info.supplier_name }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="bg-blue-100 p-2 rounded-lg shadow-md col-span-2"> <!-- Changed to col-span-2 -->
                                <div class="justify-start mb-2">
                                    <p class="font-semibold" style="font-size:12px">Purchase Description:</p>
                                    <p style="font-size:12px">{{ base_info.purchase_order_description }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="grid grid-cols-1 md:grid-cols-1 gap-6">
                            <!-- Item Details Column -->
                            <div class="p-4 rounded-lg shadow-md">
                                <table style="font-size:13px" class="min-w-full border-collapse border border-gray-300">
                                    <thead>
                                        <tr class="bg-gray-100">
                                            <th class="border border-gray-300 px-4 py-2 text-left">
                                                Code
                                            </th>
                                            <th class="border border-gray-300 px-4 py-2 text-left">
                                                Name
                                            </th>


                                            <th class="border border-gray-300 px-4 py-2 text-left">
                                                Cost Price
                                            </th>
                                            <th class="border border-gray-300 px-4 py-2 text-left">
                                                Quantity
                                            </th>

                                            <th class="border border-gray-300 px-4 py-2 text-left">
                                                Total Price
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in items_list" :key="index"
                                            class="odd:bg-white even:bg-gray-50">
                                            <td class="border border-gray-300 px-4 py-2">
                                                {{ item.code }}
                                            </td>
                                            <td class="border border-gray-300 px-4 py-2">
                                                {{ item.name }}
                                            </td>

                                            <td class="border border-gray-300 px-4 py-2">
                                                {{ item.cost_price }}
                                            </td>

                                            <td class="border border-gray-300 px-4 py-2">
                                                {{ item.quantity }}
                                            </td>

                                            <td class="border border-gray-300 px-4 py-2">
                                                {{ item.total }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </template>
            </Modal>
        </template>
    </MainLayout>
</template>
<script>
import MainLayout from '@/components/MainLayout.vue'
import apiClient from '@/services/apigen'; // Import your configured Axios instance  
import { ref, watch, onMounted } from 'vue';
import { VueGoodTable } from 'vue-good-table-next';
import Modal from '@/components/utils/Modal3.vue'

import 'vue-good-table-next/dist/vue-good-table-next.css';
import Loader from '@/components/utils/loader.vue';
import Swal from 'sweetalert2';
import Notification from '@/components/utils/Notification.vue';
import AccessControl from '@/components/utils/UserAccess.vue';

export default {
    name: 'SupplierListPage',
    components: {
        MainLayout,
        VueGoodTable,
        Modal,
        Notification,
        AccessControl
    },async mounted() {
        let number = this.$route.query.number; // Accessing route params via 'this'
        
        if(number!=""){
            this.searchTerm = number;
        }
    },
    setup() {
        const columns = ref([
            { label: '#', field: 'purchase_order_id', type: 'number', sortable: true },
            { label: 'Order Number', field: 'purchase_order_number', sortable: true },
            { label: 'Order Date', field: 'purchase_order_date', sortable: true },
            { label: 'Payment', field: 'payment_name', sortable: true },
            { label: 'Grand Total', field: 'grand_total', sortable: true },
            { label: 'Payment Reference', field: 'payment_reference', sortable: true },
            { label: 'Supplier', field: 'supplier_name', sortable: true },
            { label: 'Is Posted', field: 'is_posted', sortable: true },
           
        ]);

        const rows = ref([]);
        const totalRows = ref(0);
        const searchTerm = ref('');
        const currentPage = ref(1);
        const perPage = ref(20);
        const sortField = ref('supplier_name');
        const sortType = ref('asc');
        const dateFrom = ref('');
        const dateTo = ref('');
        const status = ref('');

        const paginationOptions = ref({
            enabled: true,
            mode: 'pages',
            perPageDropdown: [5, 10, 20],
            perPage: perPage.value,
            totalRecords: totalRows.value,
            currentPage: currentPage.value

        });

        const searchOptions = {
            enabled: true
        };

        // Function to fetch data from the server
        const fetchData = async (page) => {
            try {
                const params = {
                    page: page,
                    per_page: perPage.value,
                    sort_field: sortField.value,
                    sort_type: sortType.value,
                    search: searchTerm.value,
                    date_from: dateFrom.value,
                    date_to: dateTo.value,
                    status:status.value,
                };
                const { data } = await apiClient.get('/purchase_order_report', { params });
                rows.value = data.data || [];
                totalRows.value = data.total || 0;

            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        const onPageChange = ({ currentPage }) => {
            paginationOptions.currentPage = currentPage;
            fetchData(currentPage);
        };

        const onPerPageChange = ({ currentPerPage }) => {
            console.log('Per page changed to:', currentPerPage);
            perPage.value = currentPerPage;
            paginationOptions.value.perPage = currentPerPage;
            fetchData();
        };

        const onSortChange = ([sort]) => {
            console.log('Sort changed to:', sort);
            sortField.value = sort.field;
            sortType.value = sort.type;
            fetchData();
        };
        // Watch for changes to trigger fetchData
        watch([currentPage, perPage, sortField, sortType, searchTerm, dateFrom, dateTo,status], fetchData, { immediate: true });
        return {
            columns,
            rows,
            totalRows,
            paginationOptions,
            searchTerm,
            onPageChange,
            onPerPageChange,
            onSortChange,
            isLoading: false,
            dateFrom,
            dateTo,
            status
        };
    },
    methods: {



        async generate_report_detail(data) {

            let id = data.purchase_order_id;

            const params = {
                id: id,

            };

            try {
                const response = await apiClient.get(`/report/purchase_detail`, {
                    params,
                    responseType: 'blob', // Important for binary data
                });



                // Create a blob from the response data
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Purchase_Detail.xlsx'); // Set the desired file name
                document.body.appendChild(link);
                link.click();
                // Clean up the link
                link.parentNode.removeChild(link);
                this.export_loading = false;
            } catch (error) {
                console.error('Error downloading customer report:', error);
            }
        },
        async generate_report() {

            let fromDate = this.dateFrom;
            let toDate = this.dateTo;
            let searchTerm = this.searchTerm;
            let status = this.status;

            if (fromDate == "" || toDate == "") {
                this.$refs.notificationRef.show("Please select Date Range", 'error', '0');
                return false;
            }
            this.export_loading = true; // Set loading state to true
            const params = {
                search: searchTerm,
                date_from: fromDate,
                date_to: toDate,
                status:status
            };

            try {
                const response = await apiClient.get(`/report/purchase_order`, {
                    params,
                    responseType: 'blob', // Important for binary data
                });

                // Create a blob from the response data
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Purchase.xlsx'); // Set the desired file name
                document.body.appendChild(link);
                link.click();
                // Clean up the link
                link.parentNode.removeChild(link);
                this.export_loading = false;
            } catch (error) {
                console.error('Error downloading purchase_order report:', error);
            }

        },


        async showDetails(data) {
            let id = data.purchase_order_id;
            this.showModalDetail = true;
            try {
                const response = await apiClient.get(`/purchase_order/detail/${id}`);
                if (response.data.status == true) { // or check for other success c
                    this.items_list = response.data.data.items; // Store the fetched data
                    this.base_info = response.data.data.info;
                }
            } catch (error) {
                console.error('Error submitting form:', error.response);
            }
        },

        openAdd() {
            window.location = '/purchase/add';
        },
        showProduct(data) {
            window.location = '/purchase/get_products/' + data.supplier_id;
        },
        handleEdit(data) {

            window.location = '/purchase/edit/' + data.purchase_order_id;
            //this.showInfo(data.supplier_id);
        },
        handleDelete(data) {

            let purchase_order_id = data.purchase_order_id;

            Swal.fire({
                title: 'Are you sure do you want to delete?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, cancel!'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteData(purchase_order_id);
                }
            });
        },
        async deleteData(id) {

            try {
                const response = await apiClient.delete('/purchase_order/delete', { data: { id: id } });
                if (response.data.status == true) { // or check for other success c
                    this.$refs.notificationRef.show('Successfully Deleted', 'success', '1');
                } else {
                    this.$refs.notificationRef.show(response.data.message, 'error', '0');
                }
            } catch (error) {
                console.error('Error submitting form:', error.response);
                loader.hide();
            }
        }, handleAccess({ canEdit, canDelete, canWrite }) {
            this.canEdit = canEdit;
            this.canDelete = canDelete;
            this.canWrite = canWrite;
        },



    },
    data() {
        return {
            showModal: false,
            form: {},
            showModal2: false,
            currentItem: [

            ],
            countty_list: [],
            province_list: [],
            city_list: [],
            edit_first: 0,
            items_list: {},
            base_info: {},
            showModalDetail: false,
            export_loading: false,
        };
    },


}
</script>


<style></style>