<template>
    <AccessControl :menuId="3" :menuSectionId="7" nameOfPage='Product List' @accessGranted="handleAccess">
    </AccessControl>
    <MainLayout>
        <template v-slot:default>
            <!-- end: container -->
            <!-- begin: container -->
            <div class="container-fixed">
                <div class="flex flex-wrap items-center lg:items-end justify-between gap-5 pb-7.5">
                    <div class="flex flex-col justify-center gap-2">
                        <h1 class="text-xl font-semibold leading-none text-gray-900">
                            Product List
                        </h1>
                    </div>
                    <div class="flex items-center gap-2.5">
                        <a v-if="canWrite" class="btn btn-sm btn-success" @click="openAdd">
                            Add Product
                        </a>
                    </div>
                </div>

                <Notification ref="notificationRef" />
                <div class="card">
                    <div class="card-header   card-header-bg flex-wrap gap-2">
                        <div class="flex flex-wrap gap-2 lg:gap-6">
                            <div class="flex"><label class="input input-sm"><i class="ki-filled ki-magnifier"></i><input
                                        value="" v-model="searchTerm" type="text" placeholder="Search table"
                                        @input="fetchData"></label>

                                <label class="input input-sm" style="margin-left:10px;">Date From:<input value=""
                                        v-model="dateFrom" type="date" @input="fetchData"></label>

                                <label class="input input-sm" style="margin-left:10px;">Date To:<input value=""
                                        v-model="dateTo" type="date" @input="fetchData"></label>

                                <label class="input input-sm" style="margin-left:10px;">Category:
                                    <select class="bg-gray-50 border " placeholder="Select"
                                        v-model="cat" ref="category_id">
                                        <option value="0" selected>- Select -</option>
                                        <!-- Placeholder option -->
                                        <option v-for="category in category_list" :key="category.category_id"
                                            :value="category.category_id">
                                            {{ category.category_name }}
                                        </option>
                                    </select>
                                </label> 

                                <label class="input input-sm" style="margin-left:10px;">
                                    Warehouse:
                                    <select ref="ware_house_id" v-model="ware"
                                        class="bg-gray-50 border ">
                                        <option value="0" selected>- Select -</option>
                                        <option v-for="warehouse in ware_house_list" :key="warehouse.warehouse_id"
                                            :value="warehouse.warehouse_id">
                                            {{ warehouse.warehouse_name }}
                                        </option>
                                    </select>
                                </label>


                                <div>
                                    <button :disabled="export_loading" type="button" style="margin-left:10px;"
                                        @click="generate_report" ref="addRow"
                                        class="w-32 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 mr-1">
                                        <span v-if="export_loading" class="spinner"></span>
                                        <span v-else>Generate Report</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <vue-good-table mode="remote" :server-side="true" :columns="columns" :rows="rows"
                        :total-rows="totalRows" :pagination-options="paginationOptions" :isLoading.sync="isLoading"
                        :search-options="{ enabled: false }" @page-change="onPageChange" @on-page-change="onPageChange"
                        @per-page-change="onPerPageChange" @sort-change="onSortChange">
                        <template #table-row="props">
                            <span v-if="props.column.field === 'actions'">


                                <a @click="showshowImage(props.row)" class="btn btn-sm btn-icon btn-clear btn-light"
                                    title="Gallery" href="#">
                                    <i class="ki-filled ki-picture">
                                    </i>
                                </a>

                                <a v-if="canEdit" @click="handleEdit(props.row)"
                                    class="btn btn-sm btn-icon btn-clear btn-light" href="#">
                                    <i class="ki-filled ki-notepad-edit">
                                    </i>
                                </a>

                                <a v-if="canDelete" @click="handleDelete(props.row)"
                                    class="btn btn-sm btn-icon btn-clear btn-light" href="#">
                                    <i class="ki-filled ki-trash">
                                    </i>
                                </a>
                            </span>
                            <span v-else>
                                {{ props.formattedRow[props.column.field] }}
                            </span>
                        </template>

                    </vue-good-table>
                    <Modal :isVisible="showModal3" @update:isVisible="showModal3 = $event" @click.stop>
                        <template v-slot:header>
                            <h2>Product Images</h2>
                        </template>
                        <template v-slot:body>
                            <form @submit.prevent="UploadImageForm" style="width: 100%;">
                                <div class="grid grid-cols-4 gap-4">
                                    <div class="col-span-1 p-10">
                                        <div class="mb-4">
                                            <label class="block text-gray-700 text-left text-sm font-bold mb-2">
                                                Upload Image (Preview)
                                            </label>

                                            <div class="flex items-center space-x-4 mt-1">
                                                <img alt="Product Image" id="product_logo" ref="product_logo"
                                                    src="@/assets/upload.png"
                                                    class="w-40 h-40 object-cover  bg-gray-300">
                                            </div>
                                            <input type="hidden" ref="product_id_image">
                                            <input type="file" id="product_logo_file" ref="product_logo_file"
                                                @change="onFileChange"
                                                class="mt-3 block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-md file:border-0 file:text-sm file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
                                                accept="image/jpeg, image/png, image/webp">

                                            <div class="flex justify-first mt-4">

                                                <button v-if="canWrite" type="submit"
                                                    class="btn-success text-white px-4 py-2 rounded hover:bg-green-600">Upload</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-span-3">
                                        <div class="mb-4">

                                            <div class="card">
                                                <div class="card-header  card-header-bg flex-wrap gap-2">
                                                    <label
                                                        class="block text-neutral-500 text-left text-sm font-bold mb-2"
                                                        style="color:white !important">
                                                        Gallery
                                                    </label>
                                                </div>
                                                <div class="card-body border-2 border-gray-500"
                                                    style="height:300px;overflow-y:scroll">
                                                    <div class="image-grid" ref="imageContainer">
                                                        <div v-for="(image, index) in product_images" :key="image.id"
                                                            :data-id="`${image.id}`" class="tile">
                                                            <!-- Clickable Image -->
                                                            <img :src="`${image_directory}${image.path}`"
                                                                :alt="image.alt"
                                                                class="absolute top-0 left-0 w-full h-full object-cover cursor-pointer"
                                                                @click="previewImage(image)" />
                                                            <!-- Remove Button -->
                                                            <button v-if="canDelete" type="button"
                                                                class="relative z-10 bg-red-500 text-white py-2 px-4 m-4 rounded hover:bg-red-600"
                                                                @click="removeImage(image)">
                                                                Remove
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </template>
                    </Modal>

                    <!-- Modal for Image Preview -->
                    <div v-if="showPreview"
                        class="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50 overflow-auto">
                        <div class="relative max-w-4xl w-full p-4">
                            <img :src="`${image_directory}${currentImage.path}`" :alt="currentImage.alt"
                                style="max-height: 500px !important;" class="w-full  object-contain rounded-lg" />
                            <!-- Close Button -->
                            <button
                                class="absolute top-4 right-4 bg-white text-black rounded-full p-2 hover:bg-gray-200"
                                @click="closePreview">
                                ✕
                            </button>
                        </div>
                    </div>

                </div>
            </div>
            <!-- end: container -->
        </template>
    </MainLayout>
</template>
<script>
import MainLayout from '@/components/MainLayout.vue'
import apiClient from '@/services/apigen'; // Import your configured Axios instance  
import apiClient_multi from '@/services/apigen_multipart'; // Import your configured Axios instance  
import { ref, watch, onMounted } from 'vue';
import { VueGoodTable } from 'vue-good-table-next';
import Modal from '@/components/utils/Modal2.vue'
import 'vue-good-table-next/dist/vue-good-table-next.css';
import Swal from 'sweetalert2';
import Notification from '@/components/utils/Notification.vue';
import AccessControl from '@/components/utils/UserAccess.vue';
import { useRouter, useRoute } from 'vue-router';

export default {
    name: 'SupplierListPage',
    components: {
        MainLayout,
        VueGoodTable,
        Modal,
        Notification,
        AccessControl
    }, async mounted() {
        let cat_id = this.$route.query.cat_id; // Accessing route params via 'this'
        let ware_id = this.$route.query.ware_id; // Accessing route params via 'this'
        let code = this.$route.query.code; // Accessing route params via 'this'
        
        if(code!=""){
            this.searchTerm = code;
        }

        await this.CategoryList(cat_id);
        await this.WarehouseList(ware_id);
        //fetchData();
    },
    setup() {
        const columns = ref([
            { label: '#', field: 'product_id', type: 'number', sortable: true },
            { label: 'Product Code', field: 'product_code', sortable: true },
            { label: 'Product Name', field: 'product_name', sortable: true },
            { label: 'Short Description', field: 'product_short_description', sortable: true },
            { label: 'High Threshold', field: 'high_qty', sortable: true },
            { label: 'Low Threshold', field: 'low_qty', sortable: true },
            { label: 'Running Balance', field: 'running_balance', sortable: true },
            { label: 'Original Price', field: 'original_price', sortable: true },
            { label: 'Selling Price', field: 'selling_price', sortable: true },
            { label: 'Discount Price', field: 'discount_price', sortable: true },
            { label: 'Weight Average Cost', field: 'weight_cost', sortable: true },

            {
                sortable: false,
                label: 'Actions',
                field: 'actions',
                type: 'actions',
                actions: [
                    {
                        label: 'Edit',
                        icon: 'edit',
                        onClick: ""
                    },
                    {
                        label: 'Delete',
                        icon: 'delete',
                        onClick: ""
                    }
                ]
            }
        ]);
        const route = useRoute()    // Access the current route    
        const rows = ref([]);
        const totalRows = ref(0);
        const searchTerm = ref('');
        const currentPage = ref(1);
        const perPage = ref(20);
        const dateFrom = ref('');
        const dateTo = ref('');
        const cat = ref(route.query.cat_id ?? 0)
        const ware = ref('0')

        const sortField = ref('product_name');
        const sortType = ref('asc');

      

        const paginationOptions = ref({
            enabled: true,
            mode: 'pages',
            perPageDropdown: [5, 10, 20],
            perPage: perPage.value,
            totalRecords: totalRows.value,
            currentPage: currentPage.value
        });

        const searchOptions = {
            enabled: true
        };

        // Function to fetch data from the server
        const fetchData = async (page) => {
            try {
                const params = {
                    page: page,
                    per_page: perPage.value,
                    sort_field: sortField.value,
                    sort_type: sortType.value,
                    search: searchTerm.value,
                    date_from: dateFrom.value,
                    date_to: dateTo.value,
                    cat: cat.value,
                    ware:ware.value,
                };

               


                const { data } = await apiClient.get('/product', { params });
                rows.value = data.data || [];
                totalRows.value = data.total || 0;

            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        const onPageChange = ({ currentPage }) => {
            paginationOptions.currentPage = currentPage;
            fetchData(currentPage);
        };

        const onPerPageChange = ({ currentPerPage }) => {
            console.log('Per page changed to:', currentPerPage);
            perPage.value = currentPerPage;
            paginationOptions.value.perPage = currentPerPage;
            fetchData();
        };

        const onSortChange = ([sort]) => {
            console.log('Sort changed to:', sort);
            sortField.value = sort.field;
            sortType.value = sort.type;
            fetchData();
        };
        // Watch for changes to trigger fetchData
        watch([currentPage, perPage, sortField, sortType, searchTerm, dateFrom, dateTo, cat,ware], fetchData, { immediate: true });
        return {
            columns,
            rows,
            totalRows,
            paginationOptions,
            searchTerm,
            onPageChange,
            onPerPageChange,
            onSortChange,
            isLoading: false,
            dateFrom,
            dateTo,
            cat,
            ware
        };
    },
    methods: {

        async generate_report() {

            let fromDate = this.dateFrom;
            let toDate = this.dateTo;
            let searchTerm = this.searchTerm;
            let cat = this.cat;
            let ware = this.ware;

            if (fromDate == "" || toDate == "") {
                this.$refs.notificationRef.show("Please select Date Range", 'error', '0');
                return false;
            }
            this.export_loading = true; // Set loading state to true
            const params = {
                search: searchTerm,
                date_from: fromDate,
                date_to: toDate,
                cat:cat,
                ware:ware
            };

            try {
                const response = await apiClient.get(`/report/products`, {
                    params,
                    responseType: 'blob', // Important for binary data
                });

                // Create a blob from the response data
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Product.xlsx'); // Set the desired file name
                document.body.appendChild(link);
                link.click();
                // Clean up the link
                link.parentNode.removeChild(link);
                this.export_loading = false;
            } catch (error) {
                console.error('Error downloading report:', error);
            }
        },


        openAdd() {
            window.location = '/product/add';
        },

        previewImage(image) {
            this.currentImage = image;
            this.showPreview = true;
            this.showModal3 = false;
        },
        // Close the modal
        closePreview() {
            this.showPreview = false;
            this.currentImage = {};
            this.showModal3 = true;
        },
        // Remove the image
        async removeImage(image) {
            let image_id = image.id;

            try {
                const response = await apiClient.get(`/product/image_delete/${image_id}`);
                if (response.data.status === true) { // or check for other success condition
                    const imageDiv = this.$refs.imageContainer.querySelector(`[data-id="${image_id}"]`);
                    if (imageDiv) {
                        imageDiv.remove(); // Remove the <div> from the DOM
                        const product_id = this.$refs.product_id_image.value;
                        //this.getImage(product_id); 
                        this.$refs.notificationRef.show("successfully Deleted", 'success', '0');


                    }
                }
            } catch (error) {
                console.error('Error fetching images:', error.response);
            } finally {

            }
        },

        async getImage(product_id) {
            let loader = this.$loading.show();
            let id = product_id;
            try {
                const response = await apiClient.get(`/product/image_get/${id}/all`);
                if (response.data.status == true) { // or check for other success condition                    
                    this.product_images = response.data.data;
                    this.image_directory = response.data.path;
                } else {
                    this.product_images = [];
                }
            } catch (error) {
                console.error('Error fetching images:', error.response);
            } finally {
                loader.hide();
            }
        },

        async UploadImageForm() {
            let loader = this.$loading.show();
            const fileInput = this.$refs.product_logo_file;
            const product_id = this.$refs.product_id_image.value;
            const file = fileInput.files[0]; // Get the first file selected
            const formData = new FormData();
            formData.append('image', file);
            formData.append('product_id', product_id);
            const response2 = await apiClient_multi.post('/product/upload_image', formData);
            loader.hide();
            if (response2.data.status == true) { // or check for other success 

                this.$refs.notificationRef.show('Successfully Saved', 'success', '0');
                this.getImage(product_id);
                this.$refs.product_logo_file.value = '';
                this.$refs.product_logo.src = require("@/assets/upload.png");

            } else {
                this.$refs.notificationRef.show(response.data.message, 'error', '0');

            }

        },

        onFileChange(e) {
            const logoPreview = this.$refs.product_logo;
            const file = e.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = (event) => {
                    if (logoPreview) {
                        logoPreview.src = event.target.result;
                    }
                };
                reader.readAsDataURL(file);
            }

        },
        showshowImage(data) {
            this.showModal3 = true; // Show the modal 
            this.$nextTick(() => {
                this.$refs.product_id_image.value = data.product_id;
                this.getImage(data.product_id);
            });
        },


        async handleEdit(data) {
            window.location = '/product/edit/' + data.product_id;


        },
        handleDelete(data) {

            let product_id = data.product_id;
            Swal.fire({
                title: 'Are you sure do you want to delete?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, cancel!'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteData(product_id);
                }
            });
        },
        async deleteData(id) {

            try {
                const response = await apiClient.delete('/product/delete', { data: { id: id } });
                if (response.data.status == true) { // or check for other success c
                    this.$refs.notificationRef.show('Successfully Deleted', 'success', '1');
                } else {
                    this.$refs.notificationRef.show(response.data.message, 'error', '0');
                }
            } catch (error) {
                console.error('Error submitting form:', error.response);

            }
        },
        handleAccess({ canEdit, canDelete, canWrite }) {
            this.canEdit = canEdit;
            this.canDelete = canDelete;
            this.canWrite = canWrite;
        },
        async CategoryList(cat_id) {


            try {
                const response = await apiClient.get('/category/list/all');
                if (response.data.status == true) {
                    this.category_list = response.data.data;
                    this.cat = cat_id;

                    //$this.$refs.category_id.addEventListener('change', fetchData);
                  
                }
            } catch (error) {
                console.error('Error submitting formxx:');

            }
        },

        async WarehouseList(ware_id) {
            try {
                const response = await apiClient.get('/warehouse/list/all');
                if (response.data.status == true) {
                    this.ware_house_list = response.data.data;
                    this.ware = ware_id;
                    //$this.$refs.ware_house_id.addEventListener('change', fetchData);
                    
                }
            } catch (error) {
                console.error('Error submitting form:');

            }
        },



    },
    data() {
        return {
            showModal: false,
            showModal3: false,
            form: {
                category_id: 0
            },
            showModal2: false,
            currentItem: [],
            classification_list: [],
            category_list: [],
            sub_category_list: [],
            ware_house_list: [],
            rack_list: [],
            season_list: [],
            color_list: [],
            uom_list: [],
            edit_first: 0,
            product_images: [],
            image_directory: "",
            showPreview: false,
            canWrite: 0,
            canEdit: 0,
            canDelete: 0,
            export_loading: false
        };
    },
}
</script>


<style scoped>
.image-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    /* Flexible columns */
    gap: 10px;
    /* Spacing between tiles */
    overflow-x: auto;
    /* Allow horizontal scroll if needed */
}

.tile {
    position: relative;
    width: 100%;
    padding-top: 75%;
    /* Aspect ratio of the tile */
    overflow: hidden;
    background-color: #f0f0f0;
    /* Background color for tiles */
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    /* Align the button at the bottom */
    border: 2px solid #333;
    /* Add border: width, style, and color */
    border-radius: 8px;
    /* Optional: Rounded corners */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    /* Optional: Add a shadow for a raised effect */
}

.tile-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* Cover the tile area */
}

.remove-button {
    position: relative;
    background-color: #ff4d4d;
    /* Red button */
    color: white;
    border: none;
    padding: 8px;
    margin: 10px;
    cursor: pointer;
    font-size: 14px;
    border-radius: 5px;
    text-align: center;
    opacity: 0.8;
    transition: opacity 0.3s;
}

.remove-button:hover {
    opacity: 1;
    /* Make button fully visible on hover */
}
</style>