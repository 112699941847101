<template>
    <AccessControl :menuId="5" :menuSectionId="13" nameOfPage='Item Adjustment List' @accessGranted="handleAccess">
    </AccessControl>
    <MainLayout>
        <template v-slot:default>
            <!-- end: container -->
            <!-- begin: container -->
            <div class="container-fixed">
                <div class="flex flex-wrap items-center lg:items-end justify-between gap-5 pb-7.5">
                    <div class="flex flex-col justify-center gap-2">
                        <h1 class="text-xl font-semibold leading-none text-gray-900">
                            Item Adjustment Edit
                        </h1>
                    </div>
                    <div class="flex items-center gap-2.5">
                        <a class="btn btn-sm btn-success" @click="backToList"> Back Item Adjustment List </a>
                    </div>
                </div>
                <Notification ref="notificationRef" />
                <div class="card">
                    <div class="card-header card-header-bg flex-wrap gap-2"></div>
                    <div class="card-body">
                        <form @submit.prevent="updateForm" style="width: 100%" ref="form">



                            <div class="grid grid-cols-3 gap-4">
                                <div class="col-span-4">
                                    <div class="card">


                                        <div class="card-body">
                                            <div class="grid grid-cols-4 gap-4 mb-5">
                                                <div class="col-span-1">
                                                    <label class="block text-gray-700 text-left text-sm font-bold mb-2"
                                                        for="">
                                                        Product Code / Name
                                                    </label>
                                                    <p class="text-left">{{ currentItem.product_code }} -
                                                        {{ currentItem.product_name }}</p>
                                                </div>
                                            </div>

                                            <div class="grid grid-cols-4 gap-4 mb-5">
                                                <div class="col-span-3">
                                                    <label class="block text-gray-700 text-left text-sm font-bold mb-2"
                                                        for="">
                                                        Notes
                                                    </label>
                                                    <textarea v-model="currentItem.notes"
                                                        class="shadow appearance-none border border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                        type="text" placeholder="">
                                                    </textarea>
                                                </div>
                                            </div>

                                            <div class="grid grid-cols-4 gap-4 ">
                                                <div class="col-span-1">

                                                    <label class="block text-gray-700 text-left text-sm font-bold mb-2"
                                                        for="">
                                                        Adjustment
                                                    </label>
                                                    <select id="simpleSelect" v-model="currentItem.correction"
                                                        ref="correction"
                                                        class="bg-gray-50 border border-gray-500 text-gray-700 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                                        <option disabled value="">Select</option>
                                                        <option v-for="option in correction" :key="option2"
                                                            :value="option.item_adjustment_option_id">
                                                            {{ option.item_adjustment_option }}
                                                        </option>
                                                    </select>
                                                </div>

                                               
                                                <div  v-if="currentItem && currentItem.correction ==6" class="col-span-1" >
                                                    <label class="block text-gray-700 text-left text-sm font-bold mb-2">
                                                        Order number
                                                    </label>
                                                    <input type="text" ref="order_number"  v-model="currentItem.order_number"
                                                        class="bg-gray-50 border border-gray-500 text-gray-700 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                        placeholder="">
                                                </div>
                                                
                                                    
                                                <input v-else type="hidden" ref="order_number"
                                                    class="bg-gray-50 border border-gray-500 text-gray-700 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                    placeholder="" value="">


                                                <div class="col-span-1">
                                                    <label class="block text-gray-700 text-left text-sm font-bold mb-2"
                                                        for="">
                                                        Quantity
                                                    </label>
                                                    <div class="block text-gray-700 text-left text-sm font-bold mb-2">
                                                        <input ref="pquantity" v-model="currentItem.quantity"
                                                            class="shadow appearance-none border border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                            type="number" placeholder="" min="1" value="1" />
                                                    </div>
                                                </div>

                                                <div class="col-span-1">
                                                    <label class="block text-gray-700 text-left text-sm font-bold mb-2"
                                                        for="">
                                                        &nbsp; </label>
                                                    <div class="block text-gray-700 text-left text-sm font-bold mb-2">
                                                        <button v-if="canEdit" type="submit"
                                                            class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600  mr-1">
                                                            Update
                                                        </button>

                                                        <button  v-if="canEdit" type="button" @click="handlePosted()"
                                                            class="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600">
                                                            Posted
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <!-- end: container -->
        </template>
    </MainLayout>
</template>
<script>
import MainLayout from "@/components/MainLayout.vue";
import apiClient from "@/services/apigen"; // Import your configured Axios instance
import { ref, watch, onMounted } from "vue";
import { VueGoodTable } from "vue-good-table-next";
import Modal from "@/components/utils/Modal2.vue";
import "vue-good-table-next/dist/vue-good-table-next.css";
import Loader from "@/components/utils/loader.vue";
import Swal from "sweetalert2";
import Notification from "@/components/utils/Notification.vue";
import SearchableSelect from "@/components/utils/Search.vue";
import AccessControl from '@/components/utils/UserAccess.vue';

function debounce(func, wait) {
    let timeout;
    return function (...args) {
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(this, args), wait);
    };
}

export default {
    name: "supplierListPage",
    components: {
        MainLayout,
        VueGoodTable,
        Modal,
        Notification,
        SearchableSelect,
        AccessControl
    },
    mounted() {



    },
    created() {


        let item_adjustment_id = this.$route.params.id; // Accessing route params via 'this'      
        this.showInfo(item_adjustment_id);
        this.fecthItemOption();
    },
    computed: {

    },
    methods: {


        handleSelect(option) {
            this.selectedOption = option; // Update the selected option
        },
        handleOption(option) {
            this.selectedOption = option; // Update selected option from event*/
        },
        handleKeyup3() {
            this.fetchSuggestionsDebounced3(); // Call the debounced function
        },
        handleKeyup2() {
            this.fetchSuggestionsDebounced2(); // Call the debounced function
        },
        handleKeyup() {
            this.fetchSuggestionsDebounced(); // Call the debounced function
        },
        clear_products_bottom() {
            this.productSearch2 = {};
            this.searchQuery2 = "";
            this.items = [];
        },

        async showInfo(item_adjustment_id) {

            await this.fetchSuggestions2();

            let id = item_adjustment_id;
            const response = await apiClient.get(`/item_adjustment/${id}`);

            this.currentItem = response.data.data;
            this.currentItem.correction = response.data.data.option; // Store the fetched data   


        },
        async fetchSuggestions3() {
            if (this.searchQuery3.length > 2) {
                this.indication_purchase = "(Searching...)";
                try {
                    const response = await apiClient.post("/purchase_order_product/get/list", {
                        data: { search: this.searchQuery3 },
                    });

                    if (response.data.status == true) {
                        this.suggestions3 = response.data.data;
                        if (response.data.data.length == 0) {
                            this.indication_purchase = "(Purchase Order not Found...)";
                            this.currentItem.purchase_order_id = 0;
                        } else {
                            this.indication_purchase = "";
                        }
                    }
                } catch (error) {
                    console.error("Error submitting form:", error.response);
                }
            } else {
                this.suggestions = []; // Clear suggestions if input is less than 3 characters
                this.clear_products_bottom();

            }
        },
        async fetchSuggestions() {
            if (this.searchQuery.length > 2) {
                this.indication_supplier = "(Searching...)";
                try {
                    const response = await apiClient.post("/suppplier/get/list", {
                        data: { search: this.searchQuery },
                    });

                    if (response.data.status == true) {
                        this.suggestions = response.data.data;

                        if (response.data.data.length == 0) {
                            this.indication_supplier = "(Supplier not Found...)";
                            this.currentItem.supplier_id = 0;
                        } else {
                            this.indication_supplier = "";
                        }
                    }
                } catch (error) {
                    console.error("Error submitting form:", error.response);
                }
            } else {
                this.suggestions = []; // Clear suggestions if input is less than 3 characters
                this.clear_products_bottom();

            }
        },

        async fetchSuggestions2() {


            try {
                const response = await apiClient.post(`/product/name_code/keyword2`, {
                    data: { keyword: this.searchQuery2 },
                });

                if (response.data.status == true) {
                    // this.suggestions2 = response.data.data;
                    this.options = response.data.data;
                }
            } catch (error) {
                console.error("Error submitting form:", error.response);
            }

        },
        selectSuggestion(suggestion) {

        },

        selectSuggestion2(suggestion) {

            this.searchQuery2 = suggestion.product_name; // Populate the input with the selected suggestion
            this.productSearch2 = suggestion;
            //this.form.supplier_id = suggestion.supplier_id;
            this.suggestions2 = []; // Clear suggestions list
        },

        selectSuggestion3(suggestion) {

            this.searchQuery3 = suggestion.purchase_order_number; // Populate the input with the selected suggestion
            this.productSearch3 = suggestion;
            this.currentItem.purchase_order_id = suggestion.purchase_order_id;
            this.suggestions3 = []; // Clear suggestions list
        },

        backToList() {
            window.location = `/inventory/item_adjustment_list`;
        },
        async getpayment() {
            try {
                const response = await apiClient.get("/payment/get/all");
                if (response.data.status == true) {
                    //
                    this.payment_list = response.data.data;
                }
            } catch (error) {
                console.error("Error submitting form:", error.response);
            }
        },
        async fecthItemOption() {
            try {
                const response = await apiClient.get("/item_adjustment_option/list");

                if (response.data.status == true) {
                    this.correction = response.data.data
                }
            } catch (error) {
                console.error("Error submitting form:", error.response);
            }
        },
        handlePosted() {

            const form = this.$refs.form;
            if (form.checkValidity()) {

                // Show SweetAlert confirmation dialog
                Swal.fire({
                    title: 'Are you sure do you want to Post it?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Post it!',
                    cancelButtonText: 'No, cancel!'
                }).then((result) => {
                    if (result.isConfirmed) {
                        // Proceed with form submission after confirmation
                        this.currentItem.is_posted = 1;
                        this.updateForm();  // Call your form submission logic here
                    } else {
                        // If the user cancels, stop everything
                        return false;
                    }
                });

            } else {
                // If the form is invalid, trigger the native validation popups
                form.reportValidity();
                return false;  // Prevent submission if form is invalid
            }

        },

        async updateForm() {
            let data = {};
            let correction = this.$refs.correction.value;
            if (correction == "") {
                this.$refs.notificationRef.show("Please indicate adjustment", 'error', '0');
                return false;
            }
            this.currentItem.correction = correction;
            this.currentItem.quantity = this.$refs.pquantity.value;
            this.currentItem.order_number = this.$refs.order_number.value;

            try {
                const response = await apiClient.put('/item_adjustment/update', this.currentItem);
                if (response.data.status == true) {
                    this.$refs.notificationRef.show('Successfully Updated', 'success', '/inventory/item_adjustment_list');
                } else {
                    this.$refs.notificationRef.show(response.data.message, 'error', '0');
                }
            } catch (error) {
                console.error('Error updatting form:', error.response);

            }
        }, handleAccess({ canEdit, canDelete, canWrite }) {
            this.canEdit = canEdit;
            this.canDelete = canDelete;
            this.canWrite = canWrite;
        },

    },
    data() {
        return {
            form: {},
            currentItem: [],
            suggestions: [],
            searchQuery: "",
            productSearch: [],
            isLoading: false, // Loading state
            indication_supplier: "",
            payment_list: [],
            suggestions2: [],
            searchQuery2: "",
            indication_code: "",
            items: [],
            productSearch2: {},
            suggestions3: [],
            searchQuery3: "",
            indication_purchase: "",
            selectedOption: [],
            options: [],
            correction: ['Add', 'Deduct'],
            correct_me: "",
            canWrite: 0,
            canEdit: 0,
            canDelete: 0,
            option2:[]




        };
    },
};
</script>

<style>
.search-select {
    position: relative;
}

.options-list {
    position: absolute;
    background-color: white;
    border: 1px solid #ddd;
    list-style: none;
    padding: 0;
    margin: 0;
    max-height: 200px;
    overflow-y: auto;
}

.option-item {
    padding: 10px;
    cursor: pointer;
}

.option-item:hover {
    background-color: #f0f0f0;
}

.selected-option {
    margin-top: 10px;
}
</style>
