<template>
    <AccessControl :menuId="6" :menuSectionId="14" nameOfPage='Purchase Order list' @accessGranted="handleAccess">
    </AccessControl>
    <MainLayout>
        <template v-slot:default>
            <!-- end: container -->
            <!-- begin: container -->
            <div class="container-fixed">
                <div class="flex flex-wrap items-center lg:items-end justify-between gap-5 pb-7.5">
                    <div class="flex flex-col justify-center gap-2">
                        <h1 class="text-xl font-semibold leading-none text-gray-900">
                            Purchase Order Add
                        </h1>
                    </div>
                    <div class="flex items-center gap-2.5">
                        <a class="btn btn-sm btn-success" @click="backToList"> Back Purchase List </a>
                    </div>
                </div>
                <Notification ref="notificationRef" />
                <div class="card">
                    <div class="card-header card-header-bg flex-wrap gap-2"></div>
                    <div class="card-body">
                        <form @submit.prevent="submitForm" style="width: 100%" ref="form">

                            <div class="grid grid-cols-3 gap-4">
                                <div class="col-span-1"  style="display: none;">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2" for="">
                                            Purchase Order Number
                                        </label>
                                        <input  v-model="form.purchase_order_number"
                                            class="shadow appearance-none border border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            type="text" placeholder="" />
                                    </div>
                                </div>
                                <div class="col-span-1">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2" for="">
                                            Purchase Date
                                        </label>
                                        <input required v-model="form.purchase_order_date"
                                            class="shadow appearance-none border border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            type="date" placeholder="" />
                                    </div>
                                </div>
                                <div class="col-span-1">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2" for="">
                                            Supplier <span class="text-red-500"> {{ indication_supplier }}</span>
                                        </label>

                                        <SearchableDropdown :defaultOption="defaultOption"
                                            placeholder="Choose an option" emitEventName="custom-option-selected"
                                            @custom-option-selected="handleOptionSelected" />

                                        <!--
                                        <div style="position: relative"
                                            class="block text-gray-700 text-left text-sm font-bold mb-2">
                                            
                                            <input required type="text" v-model="searchQuery" ref="supplier_id"
                                                class="shadow appearance-none border border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                @keyup="handleKeyup" placeholder="Type to search..." />
                                            <div class="suggestion-list-container">
                                                <ul v-if="suggestions.length" class="suggestion-list">
                                                    <li v-for="(suggestion, index) in suggestions" :key="index"
                                                        @click="selectSuggestion(suggestion)">
                                                        {{ suggestion.supplier_name }}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        -->
                                    </div>
                                </div>
                            </div>
                            <div class="grid grid-cols-3 gap-4">
                                <div class="col-span-2">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2">
                                            Purchase Description
                                        </label>
                                        <textarea v-model="form.purchase_order_description"
                                            class="shadow appearance-none border border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            type="text" placeholder=""></textarea>
                                    </div>
                                </div>
                            </div>

                            <div class="grid grid-cols-3 gap-4">
                                <div class="col-span-1">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2" for="">
                                            Payment
                                        </label>
                                        <select v-model="form.payment_id" ref="payment_id"
                                            class="bg-gray-50 border border-gray-500 text-gray-700 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                            <option value="0" selected>- Select -</option>
                                            <!-- Placeholder option -->
                                            <option v-for="payment in payment_list" :key="payment.payment_id"
                                                :value="payment.payment_id">
                                                {{ payment.payment_name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-span-1">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2" for="">
                                            Payment Reference
                                        </label>
                                        <input v-model="form.payment_reference"
                                            class="shadow appearance-none border border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            type="text" placeholder="" />
                                    </div>
                                </div>
                            </div>

                            <div class="grid grid-cols-3 gap-4">
                                <div class="col-span-4">
                                    <div class="card">
                                        <div class="card-header bg-black flex-wrap gap-2">
                                            <div class="flex flex-wrap gap-2 lg:gap-5">
                                                <div class="flex">
                                                    <label class="text-white"> Product Item(s) </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card-body">
                                            <div class="grid grid-cols-4 gap-4">
                                                <div class="col-span-1">
                                                    <label class="block text-gray-700 text-left text-sm font-bold mb-2"
                                                        for="">
                                                        Supplier Product Code / Name
                                                    </label>


                                                    <SearchableSelect :options="options"
                                                        :labelKey="'product_info'"
                                                        placeholder="Search and select" ref="select_product"
                                                        @option-selected="handleOptionSelectedProduct"
                                                        @enter-selected="trigger_add" />

                                                    <!---<input type="text" v-model="searchQuery2"
                                                            class="shadow appearance-none border border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                            @keyup="handleKeyup2" placeholder="Type to search..." />
                                                        <div class="suggestion-list-container">
                                                            <ul v-if="suggestions2.length" class="suggestion-list">
                                                                <li v-for="(suggestion2, index) in suggestions2"
                                                                    :key="index"
                                                                    @click="selectSuggestion2(suggestion2)">
                                                                    {{ suggestion2.supplier_product_name }}
                                                                </li>
                                                            </ul>
                                                        </div>

                                                        <label class="text-red-500">
                                                            {{ indication_code }}
                                                        </label>-->


                                                </div>

                                                <div class="col-span-1">
                                                    <label class="block text-gray-700 text-left text-sm font-bold mb-2"
                                                        for="">
                                                        Quantity
                                                    </label>
                                                    <div class="block text-gray-700 text-left text-sm font-bold mb-2">
                                                        <input ref="pquantity"
                                                            class="shadow appearance-none border border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                            type="number" placeholder="" min="1" value="1" />
                                                    </div>
                                                </div>

                                                <div class="col-span-1">
                                                    <label class="block text-gray-700 text-left text-sm font-bold mb-2"
                                                        for="">
                                                        &nbsp; </label>
                                                    <div class="block text-gray-700 text-left text-sm font-bold mb-2">
                                                        <button type="button" @click="addRow" ref="addRow"
                                                            class="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 mr-1">
                                                            Add Product Item
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="grid grid-cols-5 gap-4">
                                                <div class="col-span-5">
                                                    <table style="font-size:13px"
                                                        class="min-w-full border-collapse border border-gray-300">
                                                        <thead>
                                                            <tr class="bg-gray-100">
                                                                <th class="border border-gray-300 px-4 py-2 text-left">
                                                                    Code
                                                                </th>
                                                                <th class="border border-gray-300 px-4 py-2 text-left">
                                                                    Name
                                                                </th>
                                                                <th class="border border-gray-300 px-4 py-2 text-left">
                                                                    Cost
                                                                </th>
                                                                <th class="border border-gray-300 px-4 py-2 text-left">
                                                                    Quantity
                                                                </th>

                                                                <th class="border border-gray-300 px-4 py-2 text-left">
                                                                    Total
                                                                </th>

                                                                <th class="border border-gray-300 px-4 py-2 text-left">
                                                                    Action
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="(item, index) in items" :key="index"
                                                                class="odd:bg-white even:bg-gray-50">
                                                                <td class="border border-gray-300 px-4 py-2">
                                                                    {{ item.code }}
                                                                </td>
                                                                <td class="border border-gray-300 px-4 py-2">
                                                                    {{ item.name }}
                                                                </td>

                                                                <td class="border border-gray-300 px-4 py-2">
                                                                    {{ item.cost }}
                                                                </td>
                                                                <td class="border border-gray-300 px-4 py-2">
                                                                    {{ item.quantity }}
                                                                </td>

                                                                <td class="border border-gray-300 px-4 py-2">
                                                                    {{ item.total }}
                                                                </td>

                                                                <td class="border border-gray-300 px-4 py-2">
                                                                    <i @click="openModal(item)"
                                                                        class="text-blue-500 px-2 py-1 rounded hover:bg-black-600  ki-filled ki-notepad-edit fs-2tx cursor-pointer">
                                                                    </i>

                                                                    <i @click="removeRow(index)"
                                                                        class="text-red-500 px-2 py-1 rounded hover:bg-black-600 ki-filled ki-trash fs-2tx">
                                                                    </i>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="flex justify-end mt-4">
                                <button v-if="canWrite"  type="submit"
                                    class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 mr-1">
                                    Save
                                </button>
                                <button v-if="canWrite" type="button" @click="handlePosted()"
                                    class="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600">
                                    Posted
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <!-- end: container -->

            <EditModal v-if="isModalVisible" :isVisible="isModalVisible" :item="currentItem" @save="updateItem"
                @cancel="closeModal" />
        </template>

    </MainLayout>
</template>
<script>
import MainLayout from "@/components/MainLayout.vue";
import apiClient from "@/services/apigen"; // Import your configured Axios instance
import { ref, watch, onMounted } from "vue";
import { VueGoodTable } from "vue-good-table-next";
import Modal from "@/components/utils/Modal2.vue";
import "vue-good-table-next/dist/vue-good-table-next.css";
import Loader from "@/components/utils/loader.vue";
import Swal from "sweetalert2";
import Notification from "@/components/utils/Notification.vue";
import SearchableDropdown from '@/components/utils/supplier_com.vue';
import SearchableSelect from "@/components/utils/Search.vue";
import EditModal from '@/components/utils/Items_quantityModal.vue'; // Adjust the path as necessary
import AccessControl from '@/components/utils/UserAccess.vue';

function debounce(func, wait) {
    let timeout;
    return function (...args) {
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(this, args), wait);
    };
}

export default {
    name: "supplierListPage",
    components: {
        MainLayout,
        VueGoodTable,
        Modal,
        Notification,
        SearchableDropdown,
        SearchableSelect,
        EditModal,
        AccessControl
    },
    mounted() {
        this.getpayment(); // Call the function here
        this.form.payment_id = 0;

    },
    created() {
        // Initialize the debounced function
        this.fetchSuggestionsDebounced = debounce(this.fetchSuggestions, 1000);
        this.fetchSuggestionsDebounced2 = debounce(this.fetchSuggestions2, 1000);
    },
    methods: {


        openModal(item) {
            this.currentItem = item; // Set the current item to edit
            this.isModalVisible = true; // Show the modal
        },
        closeModal() {
            this.isModalVisible = false; // Hide the modal
            this.currentItem = null; // Reset the current item
        },
        updateItem(updatedItem) {
            // Update the item in the items array
            const index = this.items.findIndex(item => item.code === updatedItem.code);
            if (index !== -1) {

                let total = (parseFloat(updatedItem.quantity) * parseFloat(updatedItem.cost)).toFixed(2);
                updatedItem.total = total; ///updatedItem.quantity;

                this.items.splice(index, 1, updatedItem); // Replace the item

            }
            this.closeModal(); // Close the modal
        },


        handleOptionSelected(option) {
            this.selected_supplier = option;

            if (Object.keys(this.selected_supplier).length != 0) {
                this.fetch_supplier_product();
            }

        },

        handleOptionSelectedProduct(option) {
            this.productSearch2 = option;
        },

        handlePosted() {

            const form = this.$refs.form;
            if (form.checkValidity()) {

                let data = {};
                data.items = this.items;

                // Check if items array is empty
                if (data.items.length == 0) {
                    // Trigger the notification if no items are selected
                    this.$refs.notificationRef.show("Please Select Product", 'error', '0');
                    return false;  // Prevent submission
                }

                // Show SweetAlert confirmation dialog
                Swal.fire({
                    title: 'Are you sure do you want to Post it?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Post it!',
                    cancelButtonText: 'No, cancel!'
                }).then((result) => {
                    if (result.isConfirmed) {
                        // Proceed with form submission after confirmation
                        this.form.is_posted = 1;
                        this.submitForm();  // Call your form submission logic here
                    } else {
                        // If the user cancels, stop everything
                        return false;
                    }
                });

            } else {
                // If the form is invalid, trigger the native validation popups
                form.reportValidity();
                return false;  // Prevent submission if form is invalid
            }

        },

        trigger_add(option) {
            setTimeout(() => {
                this.$refs.addRow.click();
            }, 500);
        },



        addRow() {
            this.$refs.select_product.clearInput();
            if (Object.keys(this.productSearch2).length == 0) {
                this.$refs.notificationRef.show("Product Not found", 'error', '0');
                return false;
            }

            if (parseInt(Object.keys(this.items).length) != 0) {
                const index = this.items.findIndex(item => item.supplier_product_id === this.productSearch2.supplier_product_id);
                if (index !== -1) {
                    // Get the quantity from the reference and parse it
                    let quantityToAdd = parseInt(this.$refs.pquantity.value) || 0; // default to 0 if not a valid number                

                    // Update the existing item's quantity by adding the new quantity
                    let q = parseInt(this.items[index].quantity) + parseInt(quantityToAdd);
                    this.items[index].quantity = q;
                    this.items[index].total = parseInt(q) * parseFloat(this.items[index].cost);
                    this.$refs.notificationRef.show("Product Added", 'success', '0');
                    return false;
                }
            }


            /*if (parseInt(Object.keys(this.items).length) != 0) {
                const exists = this.items.some(item => item.supplier_product_id === this.productSearch2.supplier_product_id);
                if (exists == true) {
                    this.$refs.notificationRef.show("Product already added to your list", 'error', '0');
                    return false;
                }
            }*/

            let code = this.productSearch2.supplier_product_code;
            let name = this.productSearch2.supplier_product_name;
            let cost = this.productSearch2.supplier_product_cost_price;
            let quantity = this.$refs.pquantity.value;
            let total = (parseFloat(quantity * cost)).toFixed(2);;
            let supplier_product_id = this.productSearch2.supplier_product_id;
            // Add a new row with default values
            this.items.push({ supplier_product_id: supplier_product_id, code: code, name: name, cost: cost, quantity: quantity, total: total });
            this.searchQuery2 = "";
            this.productSearch2 = {};
        },
        removeRow(index) {
            // Remove the row at the specified index
            this.items.splice(index, 1);
        },

        handleKeyup2() {
            this.fetchSuggestionsDebounced2(); // Call the debounced function
        },
        handleKeyup() {
            this.fetchSuggestionsDebounced(); // Call the debounced function
        },
        clear_products_bottom() {
            this.productSearch2 = {};
            this.searchQuery2 = "";
            this.items = [];

        },

        async fetch_supplier_product() {

            let supplier_id = this.selected_supplier.id;

            try {
                const response = await apiClient.get(`/supplier_get_product/${supplier_id}`);

                if (response.data.status == true) {
                    // this.suggestions2 = response.data.data;
                    this.options = response.data.data;
                }
            } catch (error) {
                console.error("Error submitting form:", error.response);
            }

        },
        async fetchSuggestions() {
            if (this.searchQuery.length > 2) {
                this.indication_supplier = "(Searching...)";
                try {
                    const response = await apiClient.post("/suppplier/get/list", {
                        data: { search: this.searchQuery },
                    });

                    if (response.data.status == true) {
                        this.suggestions = response.data.data;

                        if (response.data.data.length == 0) {
                            this.indication_supplier = "(Supplier not Found...)";
                            this.form.supplier_id = 0;
                            this.clear_products_bottom();

                        } else {
                            this.indication_supplier = "";
                            this.clear_products_bottom();
                        }
                    }
                } catch (error) {
                    console.error("Error submitting form:", error.response);
                }
            } else {
                this.suggestions = []; // Clear suggestions if input is less than 3 characters
                this.clear_products_bottom();

            }
        },

        async fetchSuggestions2() {

            if (this.searchQuery2.length > 2) {
                this.indication_code = "(Searching...)";
                try {
                    const response = await apiClient.post(`/supplier_pruchase_order/get/product`, {
                        data: { search: this.searchQuery2, supplier_id: this.form.supplier_id },
                    });

                    if (response.data.status == true) {

                        this.suggestions2 = response.data.data;
                        if (response.data.data.length == 0) {
                            this.indication_code = "(Product not Found...)";
                        } else {
                            this.indication_code = "";
                        }
                    }
                } catch (error) {
                    console.error("Error submitting form:", error.response);
                }
            } else {
                this.suggestions2 = []; // Clear suggestions if input is less than 3 characters
            }
        },



        selectSuggestion(suggestion) {
            this.searchQuery = suggestion.supplier_name; // Populate the input with the selected suggestion
            this.productSearch = suggestion;
            this.form.supplier_id = suggestion.supplier_id;
            this.suggestions = []; // Clear suggestions list
        },

        selectSuggestion2(suggestion) {
            this.searchQuery2 = suggestion.supplier_product_name; // Populate the input with the selected suggestion
            this.productSearch2 = suggestion;
            //this.form.supplier_id = suggestion.supplier_id;
            this.suggestions2 = []; // Clear suggestions list
        },

        backToList() {
            window.location = `/purchase/list`;
        },
        async getpayment() {
            try {
                const response = await apiClient.get("/payment/get/all");
                if (response.data.status == true) {
                    //
                    this.payment_list = response.data.data;
                }
            } catch (error) {
                console.error("Error submitting form:", error.response);
            }
        },
        async submitForm() {

            let data = {};
            data.items = this.items;
            this.form = { ...this.form, ...data };



            if (Object.keys(this.selected_supplier).length == 0) {
                this.$refs.notificationRef.show("Please Select Supplier", 'error', '0');
                return false;
            }

            if (data.items.length == 0) {
                this.$refs.notificationRef.show("Please Select Product", 'error', '0');
                return false;
            }

            this.form.supplier_id = this.selected_supplier.id;

            try {
                const response = await apiClient.post(`/purchase_order/save`, this.form);
                if (response.data.status == true) {
                    this.$refs.notificationRef.show('Successfully Saved', 'success', '/purchase/list');
                } else {
                    this.$refs.notificationRef.show(response.data.message, 'error', '0');
                }
            } catch (error) {
                console.error('Error submitting form:', error.response);

            }
        },
        handleAccess({ canEdit, canDelete, canWrite }) {
            this.canEdit = canEdit;
            this.canDelete = canDelete;
            this.canWrite = canWrite;
        },


    },
    data() {
        return {
            form: {},
            currentItem: [],
            suggestions: [],
            searchQuery: "",
            productSearch: [],
            isLoading: false, // Loading state
            indication_supplier: "",
            payment_list: [],
            suggestions2: [],
            searchQuery2: "",
            indication_code: "",
            items: [],
            productSearch2: {},
            defaultOption: {}, // Example default option
            selected_supplier: {},
            options: [],
            isModalVisible: false,
            canWrite: 0,
            canEdit: 0,
            canDelete: 0,


        };
    },
};
</script>

<style>
.search-select {
    position: relative;
}

.options-list {
    position: absolute;
    background-color: white;
    border: 1px solid #ddd;
    list-style: none;
    padding: 0;
    margin: 0;
    max-height: 200px;
    overflow-y: auto;
}

.option-item {
    padding: 10px;
    cursor: pointer;
}

.option-item:hover {
    background-color: #f0f0f0;
}

.selected-option {
    margin-top: 10px;
}
</style>
