<template>
  <div v-if="isVisible" class="modal-overlay">
    <div class="modal-content">
      <h2>Edit Item</h2>
      <label for="retail" class="block text-gray-700 text-left text-sm font-bold mb-2">Notes:</label>
      <textarea v-model="localItem.notes"
        class="shadow appearance-none border border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"></textarea>

      <label for="quantity" class="block text-gray-700 text-left text-sm font-bold mb-2">Quantity:</label>
      <input type="number" min="1" v-model="localItem.quantity"
        class="shadow appearance-none border border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />

      <label for="adjustment" class="block text-gray-700 text-left text-sm font-bold mb-2">Adjustment:</label>
      <select id="simpleSelect" ref="adjustmentx" v-model="localItem.adjustment_id" @change="resetOrderNumber"
        class="bg-gray-50 border border-gray-500 text-gray-700 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">

        <option v-for="option in correction2" :value="option.item_adjustment_option_id">
          {{ option.item_adjustment_option }}
        </option>
      </select>

      <div v-if="localItem.adjustment_id === 6">
        <label for="order_number" class="block text-gray-700 text-left text-sm font-bold mb-2">Order Number:</label>
        <input type="text" v-model="localItem.order_number"
          class="shadow appearance-none border border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
      </div>     

      <div class="modal-buttons">
        <button @click="save" class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 mr-1">Save</button>
        <button @click="cancel" class="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600">Cancel</button>
      </div>
    </div>
  </div>
</template>

<script>
import apiClient from "@/services/apigen"; // Import your configured Axios instance
export default {
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {

      localItem: { ...this.item }, // Create a local copy of the item for editing
      correction2: [],
    };
  },
  mounted() {
    this.fecthItemOption();

  },

  methods: {

    resetOrderNumber() {
      if (this.localItem.adjustment_id !== 6) {
        this.localItem.order_number = "";
      }
    },

    save() {

      this.localItem.adjustment_id = this.$refs.adjustmentx.value;
      const selectedOption = this.correction2.find(option => option.item_adjustment_option_id === parseInt(this.localItem.adjustment_id));

      this.localItem.adjustment = selectedOption.item_adjustment_option;

      this.$emit('save', this.localItem); // Emit the edited item back to the parent
    },
    cancel() {
      this.$emit('cancel'); // Emit cancel event
    },
    async fecthItemOption() {


      try {
        const response = await apiClient.get("/item_adjustment_option/list");
        if (response.data.status == true) {
          this.correction2 = response.data.data
        }
      } catch (error) {
        console.error("Error submitting form:", error.response);
      }



    },



  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  width: 300px;
}

.modal-buttons {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
</style>